import { cn } from '@hapstack/common'
import type { Vendor as VendorType } from '@hapstack/db'
import { TextLink } from '@hapstack/remix'
import {
  Avatar,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@hapstack/ui'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { type ComponentProps } from 'react'

import { useAuthenticatedUser } from '~/routes/_dashboard/useAuthenticatedUser'

const Vendor = ({ className, children, ...props }: ComponentProps<'div'>) => {
  return (
    <div
      className={cn('flex items-center gap-2', className)}
      {...props}
    >
      {children}
    </div>
  )
}

const vendorLogoStyles = cva(
  'aspect-square overflow-hidden rounded-lg border border-border bg-background',
  {
    variants: {
      size: {
        xxs: 'size-4 rounded-sm text-xxs p-0.5',
        xs: 'size-5 rounded-md text-xs p-0.5',
        sm: 'size-7 text-sm p-1',
        md: 'size-9 text-lg p-1.5 shadow-sm',
        lg: 'size-12 text-2xl p-1.5 shadow-sm ',
        xl: 'size-16 text-4xl p-3',
      },
    },
    defaultVariants: {
      size: 'xs',
    },
  }
)

type LogoStyleProps = VariantProps<typeof vendorLogoStyles>

type VendorLogoProps = {
  url?: VendorType['logoUrl']
  name?: string
} & ComponentProps<typeof Avatar> &
  LogoStyleProps

const VendorLogo = ({
  url,
  name,
  size,
  fallback,
  className,
  ...props
}: VendorLogoProps) => {
  return (
    <Avatar
      src={url}
      fallback={name || fallback}
      className={cn(vendorLogoStyles({ size }), className)}
      {...props}
    />
  )
}

const VendorName = ({
  id,
  name,
  description,
  logoUrl,
  hideTooltip = false,
  className,
}: {
  id: VendorType['id']
  name: VendorType['name']
  description?: VendorType['description']
  logoUrl?: VendorType['logoUrl']
  hideTooltip?: boolean
  className?: string
}) => {
  const { role } = useAuthenticatedUser()

  const showTooltip = !hideTooltip && description

  return (
    <TooltipProvider skipDelayDuration={100}>
      <Tooltip delayDuration={1000}>
        <TooltipTrigger asChild>
          {role === 'admin' ? (
            <TextLink
              to={`/apps/${id}`}
              variant="underline-on-hover"
              prefetch="intent"
              className={cn('max-w-52 truncate font-medium', className)}
            >
              {name}
            </TextLink>
          ) : (
            <span
              className={cn(
                'max-w-52 cursor-default truncate font-medium',
                className
              )}
            >
              {name}
            </span>
          )}
        </TooltipTrigger>
        <TooltipContent
          align="center"
          className={cn('w-72 space-y-2 p-4', !showTooltip && 'hidden')}
        >
          <div className="flex items-center gap-2">
            <VendorLogo
              url={logoUrl}
              size="xs"
            />
            <div className="text-base leading-tight">{name}</div>
          </div>
          <Separator />
          <p className="font-normal">{description}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export { Vendor, VendorLogo, VendorName }
